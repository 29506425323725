import api from '../../services/api';

export interface TrendResponse {
  name: string;
  childTrends: Array<TrendResponse>;
  items: Array<ItemResponse>;
}

export interface ItemResponse {
  id: string;
  name: string;
  typeId: number;
}

export const fetchDataHistoryTrendLibrary = async (assetId: string, groupName: string | undefined) => {
  try {
    return await api
      .get('DataHistory?assetId=' + assetId + '&groupName=' + groupName, {
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(function (response: any) {
        if (response) {
          return response.data;
        }
      });
  } catch (error) {
    console.error('Error fetching initial state:', error);
    throw error;
  }
};

export const fetchDataHistoryTrendItems = async (
  assetId = '',
  chart1Type = '',
  chart1ItemId = '',
  chart2Type = '',
  chart2ItemId = '',
  chart3Type = '',
  chart3ItemId = '',
  chart4Type = '',
  chart4ItemId = '',
  startDate = '',
  endDate = '',
  isOverlay?: number,
  groupName = '',
) => {
  try {
    const params = {
      assetId,
      chart1Type,
      chart1ItemId,
      chart2Type,
      chart2ItemId,
      chart3Type,
      chart3ItemId,
      chart4Type,
      chart4ItemId,
      startDate,
      endDate,
      isOverlay,
      groupName,
    };
    return await api
      .get('DataHistory/GetTrendDataAsync', {
        params,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(function (response: any) {
        if (!response || !response.data) {
          {
            return [];
          }
        } else {
          return response.data;
        }
      });
  } catch (error) {
    console.error('Error fetching initial state:', error);
    throw error;
  }
};

export const fetchGetDefaultTrendsData = async (
  assetId: string | undefined,
  viewId: string,
  startDate = '',
  endDate = '',
  isOverlay?: number,
) => {
  try {
    const params = {
      assetId,
      viewId,
      startDate,
      endDate,
      isOverlay,
    };
    return await api
      .get('DataHistory/GetDefaultTrendsData', {
        params,
        headers: {
          'content-type': 'application/json',
        },
      })
      .then(function (response: any) {
        if (!response || !response.data) {
          {
            return [];
          }
        } else {
          return response.data;
        }
      });
  } catch (error) {
    console.error('Error fetching initial state:', error);
    throw error;
  }
};
