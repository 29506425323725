import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { fetchDataHistoryTrendItems } from '../DataHistoryService';
import { mapObject } from './TrendLibraryUtility';

interface State {
  loading: boolean;
  error: string | undefined;
  overlayCoordinates: { chart1: []; chart2: []; chart3: []; chart4: [] };
  groupChartCoordinates: { chart1: []; chart2: []; chart3: []; chart4: [] };
  isTrendLibraryClicked?: boolean;
  isDatedUpdated?: boolean;
}

interface FetchParameters {
  assetId: string;
  chart1Type: string;
  chart1ItemId: string;
  chart2Type?: string;
  chart2ItemId?: string;
  chart3Type?: string;
  chart3ItemId?: string;
  chart4Type?: string;
  chart4ItemId?: string;
  startDate: string;
  endDate: string;
  chartIndex?: number;
  overlay?: number;
  groupName?: string;
}

// Async thunk to fetch data history trends
export const fetchTrendCoordinatesItemAsync = createAsyncThunk(
  'trendItemSlice/fetchDataHistoryTrendItems',
  async (trend: FetchParameters) => {
    const data = await fetchDataHistoryTrendItems(
      trend.assetId,
      trend.chart1ItemId,
      trend.chart1Type,
      trend.chart2ItemId,
      trend.chart2Type,
      trend.chart3ItemId,
      trend.chart3Type,
      trend.chart4ItemId,
      trend.chart4Type,
      trend.startDate,
      trend.endDate,
      trend.overlay,
      trend.groupName,
    );
    if (data) {
      return {
        values: data.values,
        chartIndex: trend.chartIndex,
        name: trend.itemId,
        chartType: trend.overlay,
        dateCreated: data.dateCreated,
      };
    }
  },
);

const initialState: State = {
  loading: false,
  error: undefined,
  overlayCoordinates: {
    chart1: [],
    chart2: [],
    chart3: [],
    chart4: [],
  },
  groupChartCoordinates: {
    chart1: [],
    chart2: [],
    chart3: [],
    chart4: [],
  },
  isTrendLibraryClicked: false,
  isDatedUpdated: false,
};

export const trendItemSlice = createSlice({
  name: 'trendItem',
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
    clearTrendCoordinates: (state) => {
      return {
        ...state,
        overlayCoordinates: {
          chart1: [],
          chart2: [],
          chart3: [],
          chart4: [],
        },
        groupChartCoordinates: {
          chart1: [],
          chart2: [],
          chart3: [],
          chart4: [],
        },
      };
    },
    updategroupChartCoordinates: (state, action) => {
      state.groupChartCoordinates = action.payload.value;
    },
    updateOverlayCoordinates: (state, action) => {
      state.overlayCoordinates = action.payload.value;
    },
    updateIsTrendLibraryClicked: (state, action) => {
      state.isTrendLibraryClicked = action.payload;
    },
    updateDateFlag: (state, action) => {
      state.isDatedUpdated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrendCoordinatesItemAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTrendCoordinatesItemAsync.fulfilled, (state, action: any) => {
        state.loading = false;
        if (action.payload.chartType === 1) {
          const prevChartData = current(state).overlayCoordinates;
          const currentChartData = mapObject(action.payload.values, action.payload.dateCreated);
          let mergedData: any = null;
          if (prevChartData && currentChartData) {
            mergedData = {
              chart1: removeDuplicates(prevChartData.chart1.concat(currentChartData.chart1)),
              chart2: removeDuplicates(prevChartData.chart2.concat(currentChartData.chart2)),
              chart3: removeDuplicates(prevChartData.chart3.concat(currentChartData.chart3)),
              chart4: removeDuplicates(prevChartData.chart4.concat(currentChartData.chart4)),
            };
            state.overlayCoordinates = mergedData;
          }
        } else {
          const prevChartData = current(state).groupChartCoordinates;
          const currentChartData = mapObject(action.payload.values, action.payload.dateCreated);
          let mergedData: any = null;
          if (prevChartData && currentChartData) {
            mergedData = {
              chart1: removeDuplicates(prevChartData.chart1.concat(currentChartData.chart1)),
              chart2: removeDuplicates(prevChartData.chart2.concat(currentChartData.chart2)),
              chart3: removeDuplicates(prevChartData.chart3.concat(currentChartData.chart3)),
              chart4: removeDuplicates(prevChartData.chart4.concat(currentChartData.chart4)),
            };
            state.groupChartCoordinates = mergedData;
          }
        }
      })
      .addCase(fetchTrendCoordinatesItemAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const trendCoordinateSlice = (state: RootState) => state.trendItem;
export const overlayCoordinates = (state: RootState) => state.trendItem.overlayCoordinates;
export const groupChartcoordinates = (state: RootState) => state.trendItem.groupChartCoordinates;
export const {
  updategroupChartCoordinates,
  updateIsTrendLibraryClicked,
  updateOverlayCoordinates,
  updateDateFlag,
  setInitialState,
  clearTrendCoordinates,
} = trendItemSlice.actions;
export default trendItemSlice.reducer;

function removeDuplicates(array) {
  return array.reduce((uniqueArray, currentItem) => {
    const existingItemIndex = uniqueArray.findIndex(
      (obj) => obj && currentItem && obj.name === currentItem.name && obj.itemId === currentItem.itemId,
    );

    // If not a duplicate, add it to the uniqueArray
    if (existingItemIndex === -1) {
      uniqueArray.push(currentItem);
    } else {
      if (new Date(currentItem.dateCreated) > new Date(uniqueArray[existingItemIndex].dateCreated)) {
        uniqueArray[existingItemIndex] = currentItem;
      }
    }
    return uniqueArray;
  }, []);
}
