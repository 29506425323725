import React, { useState, useRef, ReactNode, FC } from 'react';
import './ToolTip.scss';

interface TooltipProps {
  children: ReactNode;
  delay?: number;
  direction?: string;
  content: ReactNode;
  isGroupStatus: boolean;
}

const Tooltip: FC<TooltipProps> = (props) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [active, setActive] = useState<boolean>(false);

  const showTip = () => {
    timeoutRef.current = setTimeout(() => {
      setActive(true);
    }, props.delay || 300);
  };

  const hideTip = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setActive(false);
  };

  const className = props.isGroupStatus ? 'tooltip-wrapper group-status-tooltip' : 'tooltip-wrapper';
  return (
    <div className={className} onMouseEnter={showTip} onMouseLeave={hideTip} data-testid='tooltip-wrapper'>
      {props.children}
      {active && (
        <div data-testid='tooltip-text' className={`ui tooltip-tip ${props.direction || 'top'}`}>
          {props.content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
