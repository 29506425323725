import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface Button {
  name: string;
  active: boolean;
  id: number;
}

interface ButtonState {
  uiButtons: Array<Button>;
  notificationButtons: Array<Button>;
}

const initialState: ButtonState = {
  uiButtons: [],
  notificationButtons: [],
};

export const buttonSlice = createSlice({
  name: 'buttons',
  initialState,
  reducers: {
    setUIButtons(state, action: PayloadAction<Array<Button>>) {
      state.uiButtons = action.payload;
    },
    setNotificationButtons(state, action: PayloadAction<Array<Button>>) {
      state.notificationButtons = action.payload;
    },
    switchActiveButton(state, action: PayloadAction<{ id: number; buttonType: 'ui' | 'notification' }>) {
      const { id, buttonType } = action.payload;
      const buttons = buttonType === 'ui' ? state.uiButtons : state.notificationButtons;

      buttons.forEach((button) => {
        if (id == button.id) {
          button.active = true;
        } else {
          button.active = false;
        }
      });
    },
  },
});

export const { switchActiveButton, setUIButtons, setNotificationButtons } = buttonSlice.actions;

export const selectUIButtons = (state: RootState) => state.uiButtons;
export const selectNotificationButtons = (state: RootState) => state.notificationButtons;

export default buttonSlice.reducer;
