import React, { useMemo, useState, useEffect, useRef } from 'react';
import TrendGroup from './TrendGroup';
import TrendGroupViewChart, { defaultCurveColors } from './TrendGroupViewChart/TrendGroupViewChart';
import './TrendGroupView.scss';
import { useAppSelector, useAppDispatch } from '../../hooks/storeHooks';
import { groupChartcoordinates, trendCoordinateSlice } from '../data-history/TrendLibrary/TrendCoordinateSlice';
import { useParams } from 'react-router-dom';
import { fetchTrendCoordinatesItemAsync } from '../data-history/TrendLibrary/TrendCoordinateSlice';
import { isExistInTrendLibrary } from '../data-history/TrendLibrary/TrendLibraryUtility';
import { fetchDataHistoryTrendLibraryAsync } from './../data-history/TrendLibrary/TrendLibrarySlice';
import Loader from '../common/Loader/Loader';
import * as am5 from '@amcharts/amcharts5';
import { XYChart, XYCursor } from '@amcharts/amcharts5/xy';

const TrendGroupView = ({ startDate, endDate }: { startDate: Date | null; endDate: Date | null }) => {
  const trendLoader = useAppSelector(trendCoordinateSlice).loading;
  const groupChartcoordinatesList: any = useAppSelector(groupChartcoordinates);
  const isLoadingTrends = useAppSelector((state) => state.customViewDataSlice.groupLoading);
  const isLoadingTrendData = useAppSelector((state) => state.trendItem.loading);
  const trendsGroupSelection = useAppSelector((state) => state.trendLibrary.trendsGroupSelection);
  const { assetId } = useParams();
  const trendLibrary = useAppSelector((state) => state.trendLibrary);

  const [tooltips, setTooltips] = useState<string[]>([]);
  const [values, setValues] = useState<Record<string, string | number>>({});
  const dispatch = useAppDispatch();
  const trendItems = useAppSelector((state) => state.trendLibrary?.searchTrends);

  const customViewDataSlice = useAppSelector((state) => state.customViewDataSlice);
  const groupName = useAppSelector((state) => state.assetGroups?.selectedGroup?.assetGroupName);
  const hasDateBeenUpdated = useAppSelector((state) => state.trendItem.isDatedUpdated);
  const chartRefs = useRef<XYChart[] | null[]>([null, null, null, null]);

  useEffect(() => {
    if (!trendItems || !trendItems.length) {
      dispatch(fetchDataHistoryTrendLibraryAsync({ assetId: assetId, groupName: groupName }));
    }
  }, [assetId, groupName]);
  useEffect(() => {
    setValues({});
  }, [startDate, endDate, assetId]);

  const handleTooltipDataChanged = (tooltipText: string): void => {
    setTooltips((prevTooltips) => [...prevTooltips, tooltipText]);

    const lines = tooltipText.split('\n');
    lines.forEach((line) => {
      const name = line.trim().split(':')[0]?.trim();
      const value = line.trim()?.split(':')[1]?.trim();
      if (name && value) {
        setValues((prevValues) => ({ ...prevValues, [name]: value }));
      } else {
        console.error('value not found in the tooltip:', tooltipText);
      }
    });
  };

  const groupChartData = useMemo(() => {
    const data = trendsGroupSelection.map((group, index) => {
      if (group) {
        const groupCoordinates = groupChartcoordinatesList['chart' + (index + 1).toString()].filter((trend: any) =>
          isExistInTrendLibrary(trendLibrary, trend),
        );
        return {
          data: groupCoordinates,
        };
      }

      return undefined;
    });
    return data;
  }, [trendsGroupSelection, groupChartcoordinatesList]);

  const getMaxElement = () => {
    let maxCount = 0;
    for (const group of trendsGroupSelection) {
      const selectedTrendsArray = group.selectedTrendsArray;
      if (selectedTrendsArray.length > maxCount) {
        maxCount = selectedTrendsArray.length;
      }
    }

    return maxCount;
  };
  useEffect(() => {
    if (!startDate || !endDate) return;
    const formattedStartDate = new Date(startDate).toLocaleString('en');
    const formattedEndDate = new Date(endDate).toLocaleString('en');

    if ((!customViewDataSlice.groupLoading && !customViewDataSlice.overlayLoading) || hasDateBeenUpdated) {
      dispatch(
        fetchTrendCoordinatesItemAsync({
          assetId: assetId as string,
          chart1Type: trendsGroupSelection[0].selectedTrendsArray.map((item) => item?.name).join(','),
          chart1ItemId: trendsGroupSelection[0].selectedTrendsArray.map((item) => item?.typeId?.toString()).join(','),
          chart2Type: trendsGroupSelection[1].selectedTrendsArray.map((item) => item?.name).join(','),
          chart2ItemId: trendsGroupSelection[1].selectedTrendsArray.map((item) => item?.typeId?.toString()).join(','),
          chart3Type: trendsGroupSelection[2].selectedTrendsArray.map((item) => item?.name).join(','),
          chart3ItemId: trendsGroupSelection[2].selectedTrendsArray.map((item) => item?.typeId?.toString()).join(','),
          chart4Type: trendsGroupSelection[3].selectedTrendsArray.map((item) => item?.name).join(','),
          chart4ItemId: trendsGroupSelection[3].selectedTrendsArray.map((item) => item?.typeId?.toString()).join(','),
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          groupName: groupName,
        }),
      );
    }
  }, [startDate, endDate, assetId]);

  function updateGroupChartData(values: any, groupChartData: any) {
    groupChartData.forEach((chartData: any) => {
      chartData.data.forEach((dataPoint: any) => {
        if (Array.isArray(dataPoint.coordinates) && dataPoint.coordinates.length > 0) {
          const latestCoordinate = dataPoint.coordinates[dataPoint.coordinates.length - 1];

          if (latestCoordinate && typeof latestCoordinate.y !== 'undefined') {
            const latestValue = latestCoordinate.y.toString();

            values[dataPoint.name] = latestValue;
          } else {
            console.warn(`No valid 'y' value found for ${dataPoint.name}`);
          }
        } else {
          console.warn(`Coordinates array is missing or empty for ${dataPoint.name}`);
        }
      });
    });
    setValues((prevValues) => ({ ...prevValues, values }));
  }

  const handleMouseLeave = () => {
    updateGroupChartData(values, groupChartData);
  };

  function syncCursor() {
    const chartsToSync = [
      chartRefs.current[0],
      chartRefs.current[1],
      chartRefs.current[2],
      chartRefs.current[3],
    ] as any;

    am5.array.each(chartsToSync, function (sourceChart: any) {
      const syncCursors: any = [];
      am5.array.each(chartsToSync, function (targetChart: any) {
        if (targetChart !== sourceChart) {
          syncCursors.push(targetChart?.get('cursor'));
        }
      });
      sourceChart?.get('cursor')?.set('syncWith', syncCursors, { axis: sourceChart.xAxes.getIndex(0) });
    });
  }

  function hideCursors(opacity: number | undefined, target: XYCursor | undefined) {
    const syncedCursors = [
      chartRefs.current[0]?.get('cursor'),
      chartRefs.current[1]?.get('cursor'),
      chartRefs.current[2]?.get('cursor'),
      chartRefs.current[3]?.get('cursor'),
    ];
    am5.array.each(syncedCursors, function (cursor) {
      if (!cursor) {
        return;
      }

      if (cursor !== target) {
        cursor.set('opacity', opacity);
        if (opacity == 0) {
          cursor.set('alwaysShow', false);
        }
      }
    });
  }

  return (
    <div className='card-viewer-card'>
      <div className='flex flex-row flex-grow flex-shrink trend-group-view-container'>
        <div className='trend-group-view-chart-container'>
          {trendsGroupSelection &&
            trendsGroupSelection.length &&
            (isLoadingTrends || trendLoader || isLoadingTrendData ? (
              <Loader />
            ) : (
              <div>
                <div onMouseLeave={handleMouseLeave}>
                  <TrendGroupViewChart
                    syncCursor={syncCursor}
                    hideCursors={hideCursors}
                    div='chartdiv1'
                    trendGroup={groupChartData[0]?.data}
                    onTooltipDataChanged={handleTooltipDataChanged}
                    startDate={startDate}
                    endDate={endDate}
                    chartIndex={0}
                    maxElements={getMaxElement()}
                    ref={chartRefs}
                  />
                </div>
                <div onMouseLeave={handleMouseLeave}>
                  <TrendGroupViewChart
                    syncCursor={syncCursor}
                    hideCursors={hideCursors}
                    div='chartdiv2'
                    trendGroup={groupChartData[1]?.data}
                    onTooltipDataChanged={handleTooltipDataChanged}
                    startDate={startDate}
                    endDate={endDate}
                    chartIndex={1}
                    maxElements={getMaxElement()}
                    ref={chartRefs}
                  />
                </div>
                <div onMouseLeave={handleMouseLeave}>
                  <TrendGroupViewChart
                    syncCursor={syncCursor}
                    hideCursors={hideCursors}
                    div='chartdiv3'
                    trendGroup={groupChartData[2]?.data}
                    onTooltipDataChanged={handleTooltipDataChanged}
                    startDate={startDate}
                    endDate={endDate}
                    chartIndex={2}
                    maxElements={getMaxElement()}
                    ref={chartRefs}
                  />
                </div>
                <div onMouseLeave={handleMouseLeave}>
                  <TrendGroupViewChart
                    syncCursor={syncCursor}
                    hideCursors={hideCursors}
                    div='chartdiv4'
                    trendGroup={groupChartData[3]?.data}
                    onTooltipDataChanged={handleTooltipDataChanged}
                    startDate={startDate}
                    endDate={endDate}
                    chartIndex={3}
                    maxElements={getMaxElement()}
                    ref={chartRefs}
                  />
                </div>
              </div>
            ))}
        </div>

        <div className='trend-group-list'>
          {trendsGroupSelection &&
            trendsGroupSelection.length &&
            trendsGroupSelection.map((group: any, index) => (
              <div key={index} className='flex w-full h-full'>
                <TrendGroup
                  startDate={startDate}
                  endDate={endDate}
                  trendName={group?.groupName}
                  headerText={group?.groupName}
                  trendId={group?.id}
                  chartIndex={index}
                  values={group?.selectedTrendsArray?.map((trend: any, index: number) => ({
                    key: index,
                    id: trend.id,
                    title: trend.name,
                    value: tooltips.some((tooltip) => tooltip.includes(trend.name)) ? values[trend.name] || 0 : 0,
                    color: defaultCurveColors[index % defaultCurveColors.length].color,
                  }))}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default TrendGroupView;
