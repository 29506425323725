import CardDateList from '../CardDateList/CardDateList';
import AnalysisInputOutput from '../../common/AnalysisInputOutput/AnalysisInputOutput';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { useLocation, useParams } from 'react-router-dom';
import './RodLiftAnalysis.css';
import '../../../styles/Analysis.css';
import XDAnalysis from '../../common/XDAnalysis/XDAnalysis';
import AnalysisResultHeader from '../../common/AnalysisResultHeader/AnalysisResultHeader';
import ViewToggle1 from '../../../images/view toggle-1.svg';
import ViewToggle2 from '../../../images/view toggle-2.svg';
import { ReactComponent as Eye } from '../../../images/eye.svg';
import { ReactComponent as FilterFunnel } from '../../../images/filter-funnel-01.svg';
import UIIconButtonToolbar from '../../common/UIIconButtonToolbar/UIIconButtonToolbar';
import ViewOptions from '../../common/ViewOptions/ViewOptions';
import Tooltip from '../../common/tooltip/ToolTip';
import { CardDateItemState, fetchRodLiftAnalysisCardDatesAsync, toggleSelected } from '../CardDateSlice';
import { fetchRodLiftAnalysisCardCoordinatesAsync } from '../CardCoordinatesSlice';
import { fetchRodLiftAnalysisAsync } from '../AnalysisSlice';
import LidarScanMap from '../LidarScan';
import CardTypeFilter from '../../common/UIFilterOptions/CardTypeFilter';
import CardHeader from '../../common/CardHeader/CardHeader';
import Timeline from '../../common/timeline/Timeline';
import { AnalysisTimelineProps } from '../../asset-analysis/AssetAnalysis';
import TimelineDatepicker from '../../common/timeline/TimelineDatepicker';
import TimelineIcon from '../../common/timeline/TimelineIcon';
import Loader from '../../common/Loader/Loader';

const cardViewOptions = [
  {
    id: 1,
    label: 'Surface card',
  },
  {
    id: 2,
    label: 'POC downhole card',
  },
  {
    id: 3,
    label: 'Downhole card',
  },
  {
    id: 4,
    label: 'Predicted card',
  },
  {
    id: 5,
    label: 'Permissible load card',
  },
  {
    id: 7,
    label: 'Load limits',
  },
  {
    id: 9,
    label: 'Setpoints',
  },
];
const svgTogglePrimary = (
  <svg width='18' height='11' viewBox='0 0 18 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.5 1H5.5C4.09987 1 3.3998 1 2.86502 1.27248C2.39462 1.51217 2.01217 1.89462 1.77248 2.36502C1.5 2.8998 1.5 3.59987 1.5 5V6.28169C1.5 7.68182 1.5 8.38189 1.77248 8.91667C2.01217 9.38707 2.39462 9.76952 2.86502 10.0092C3.3998 10.2817 4.09987 10.2817 5.5 10.2817H12.5C13.9001 10.2817 14.6002 10.2817 15.135 10.0092C15.6054 9.76952 15.9878 9.38707 16.2275 8.91667C16.5 8.38189 16.5 7.68182 16.5 6.28169V5C16.5 3.59987 16.5 2.8998 16.2275 2.36502C15.9878 1.89462 15.6054 1.51217 15.135 1.27248C14.6002 1 13.9001 1 12.5 1Z'
      stroke='#0094BD'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
const svgToggleSecondary = (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.5 3.33337H6.5C5.09987 3.33337 4.3998 3.33337 3.86502 3.60248C3.39462 3.83919 3.01217 4.21691 2.77248 4.68148C2.5 5.20963 2.5 5.90102 2.5 7.2838V8.54961C2.5 9.93239 2.5 10.6238 2.77248 11.1519C3.01217 11.6165 3.39462 11.9942 3.86502 12.2309C4.3998 12.5 5.09987 12.5 6.5 12.5H13.5C14.9001 12.5 15.6002 12.5 16.135 12.2309C16.6054 11.9942 16.9878 11.6165 17.2275 11.1519C17.5 10.6238 17.5 9.93239 17.5 8.54961V7.2838C17.5 5.90102 17.5 5.20963 17.2275 4.68148C16.9878 4.21691 16.6054 3.83919 16.135 3.60248C15.6002 3.33337 14.9001 3.33337 13.5 3.33337Z'
      stroke='#F7F9F9'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <ellipse cx='3.33333' cy='16.6667' rx='0.833333' ry='0.833333' fill='#F7F9F9' />
    <ellipse cx='6.66732' cy='16.6667' rx='0.833333' ry='0.833333' fill='#F7F9F9' />
    <ellipse cx='9.99935' cy='16.6667' rx='0.833333' ry='0.833333' fill='#F7F9F9' />
    <circle cx='13.3333' cy='16.6667' r='0.833333' fill='#F7F9F9' />
    <circle cx='16.6673' cy='16.6667' r='0.833333' fill='#F7F9F9' />
  </svg>
);
const uiIconButtons = [
  {
    key: 0,
    name: 'view-toggle-1',
    active: true,
    image: ViewToggle1,
    tooltip: 'Card view',
    svgNode: svgTogglePrimary,
  },
  {
    key: 1,
    name: 'view-toggle-2',
    active: false,
    image: ViewToggle2,
    tooltip: 'Timeline view',
    svgNode: svgToggleSecondary,
  },
];

interface UrlParams {
  assetId: string;
}

interface SelectedCardTrend {
  id: number;
  label: string;
}

interface RodLiftAnalysisProps {
  timelineProps: AnalysisTimelineProps;
}

const RodLiftAnalysis = ({ timelineProps }: RodLiftAnalysisProps) => {
  const dispatch = useAppDispatch();
  const analysisStore = useAppSelector((state) => state.rodLiftAnalysisAnalysis);
  const cardDateStore = useAppSelector((state) => state.rodLiftAnalysis);
  const cardCoordinatesStore = useAppSelector((state) => state.rodLiftAnalysisCardCoordinates);
  const enabledStates = useAppSelector((state) => state.viewOptions.enabledStates);
  const isLoadingCards = useAppSelector((state) => state.rodLiftAnalysis.isLoading);
  const isLoadingCoordinates = useAppSelector((state) => state.rodLiftAnalysisCardCoordinates.isLoading);

  const { assetId } = useParams<keyof UrlParams>() as UrlParams;
  const { pathname } = useLocation();
  const {
    startDate,
    endDate,
    minDate,
    maxDate,
    isDateLinked,
    showDateLinkIcon,
    setStartDate,
    setEndDate,
    setMinDate,
    setMaxDate,
    onClickLinkIcon,
    setShowDateLinkIcon,
  } = timelineProps;

  const [uiIconButtonState, setUiIconButtonState] = useState(uiIconButtons);
  const [activeViewToggle, setActiveViewToggle] = useState('view-toggle-1');
  const [selectedCardTypeNames, setSelectedCardTypeNames] = useState<string[]>([]);

  const [isCardDataCollapsed, setIsCardDataCollapsed] = useState(false);
  const [selectedCardTrends, setSelectedCardTrends] = useState<SelectedCardTrend[]>(
    cardViewOptions.map((option) => ({ id: option.id, label: option.label })),
  );
  const [dateRangeFilteredCardDates, setDateRangeFilteredCardDates] = useState<CardDateItemState[] | null>(null);

  const isLoading = useAppSelector((state) => state.rodLiftAnalysisAnalysis.isLoading);

  useEffect(() => {
    if (!isLoadingCards && !isLoadingCoordinates && cardDateStore.values.length > 0) {
      const dates = cardDateStore.values.map((x) => new Date(x.date).getTime());
      const minDate = new Date(Math.min(...dates));
      const maxDate = new Date(Math.max(...dates));

      if (minDate == maxDate) {
        minDate.setDate(minDate.getDate() - 1);
        maxDate.setDate(maxDate.getDate() + 1);
      }

      setMinDate(minDate);
      setMaxDate(maxDate);
    }
  }, [cardDateStore.values.length, isLoadingCards, isLoadingCoordinates]);

  useEffect(() => {
    const newUiIconButtonState = uiIconButtonState.map((button) => {
      if (button.name === 'view-toggle-1') {
        return { ...button, active: true };
      } else {
        return { ...button, active: false };
      }
    });

    setUiIconButtonState(newUiIconButtonState);
    setActiveViewToggle('view-toggle-1');
  }, [assetId]);

  useEffect(() => {
    if (activeViewToggle === 'view-toggle-1') {
      setStartDate(null);
      setEndDate(null);
      setDateRangeFilteredCardDates(null);
      setShowDateLinkIcon(false);
    } else if (activeViewToggle === 'view-toggle-2') {
      setShowDateLinkIcon(true);
    }
  }, [activeViewToggle]);

  useEffect(() => {
    if (isDateLinked) {
      const newUiIconButtonState = uiIconButtonState.map((button) => {
        if (button.name === 'view-toggle-2') {
          return { ...button, active: true };
        } else {
          return { ...button, active: false };
        }
      });

      setUiIconButtonState(newUiIconButtonState);
      setActiveViewToggle('view-toggle-2');
    }
  }, [isDateLinked]);

  useEffect(() => {
    if (!isLoadingCards && !isLoadingCoordinates && startDate && endDate) {
      const filteredCardDates = cardDateStore.values.filter((x) => {
        const cardDate = new Date(x.date);
        return cardDate >= startDate && cardDate <= endDate;
      });

      setDateRangeFilteredCardDates(filteredCardDates);
    }
  }, [cardDateStore.values, startDate, endDate, isLoadingCards, isLoadingCoordinates]);

  useEffect(() => {
    const initializeCardDateStore = () => {
      dispatch(fetchRodLiftAnalysisCardDatesAsync(assetId)).then((response: any) => {
        if (response.payload?.values.length > 0) {
          const firstCardDate = response.payload.values[0].date;
          onCardDateItemClickHandler(0, firstCardDate);
        }
      });
    };

    if (assetId) {
      initializeCardDateStore();
    }
  }, [assetId, pathname]);

  const UniqueBycardTypeName = [...new Map(cardDateStore.values.map((item) => [item['cardTypeName'], item])).values()];

  const distinctCardTypeNames = [
    ...new Set(
      UniqueBycardTypeName.filter((value) => value.cardTypeName !== null && value.cardTypeName !== '').map((value) => {
        return { id: value.id, label: value.cardTypeName };
      }),
    ),
  ].sort();

  const createAnalysisResultHeaderDescription = () => {
    // Get last selected card date
    const selectedCardDate = cardDateStore.selectedCardDates.slice(-1)[0];

    const cardDateItem = cardDateStore.values.find((x) => x.date == selectedCardDate);

    if (cardDateItem) {
      return `${cardDateItem.cardTypeName} ${cardDateItem.date}`;
    }

    return '';
  };

  function toolbarButtonClicked(id: number) {
    const clickedButton = uiIconButtonState.find((button) => button.key === id);

    if (clickedButton) {
      setActiveViewToggle(clickedButton.name);

      // Toggle the 'active' property in the button data
      const updatedButtons = uiIconButtonState.map((button) => {
        if (button.key === id) {
          return { ...button, active: true };
        } else {
          return { ...button, active: false };
        }
      });

      setUiIconButtonState(updatedButtons);
    }
  }

  const onCardDateItemClickHandler = (id: number, date: string) => {
    let fetchData = true;

    // Don't fetch if toggling off card item.
    if (cardDateStore.values.find((x) => x.date == date)?.isSelected == true) {
      fetchData = false;
    }

    dispatch(toggleSelected(date));

    if (fetchData) {
      dispatch(fetchRodLiftAnalysisCardCoordinatesAsync({ assetId: assetId, cardDate: date }));
      dispatch(fetchRodLiftAnalysisAsync({ assetId: assetId, cardDate: date }));
    }

    return;
  };

  const toggleBodyCollapse = () => {
    setIsCardDataCollapsed((prevIsCardDataCollapsed) => !prevIsCardDataCollapsed);
  };

  const handlecardTypeChange = (id, value, label) => {
    value
      ? !selectedCardTypeNames.includes(label) && setSelectedCardTypeNames([...selectedCardTypeNames, label])
      : setSelectedCardTypeNames(selectedCardTypeNames.filter((item) => item !== label));
  };

  const getSelectedCards = () => {
    const selectedCardDates = cardDateStore.selectedCardDates.filter((cardDate) =>
      startDate && endDate ? new Date(cardDate) >= startDate && new Date(cardDate) <= endDate : true,
    );
    const selectedCards = selectedCardDates
      .map((cardDate) => ({
        cardDate: cardDate,
        cardTrends: cardCoordinatesStore.values[cardDate]?.cardTrends,
        color: cardDateStore.values.find((cardDateItem) => cardDateItem.date == cardDate)?.color,
      }))
      .filter((card) => card !== undefined && card.cardTrends !== undefined);
    return selectedCards;
  };

  const getSelectedAnalysisInput = () => {
    if (cardDateStore.selectedCardDates.length == 0) {
      return null;
    }

    const lastSelectedCardDate = cardDateStore.selectedCardDates.slice(-1)[0];
    return analysisStore.values[lastSelectedCardDate]?.input.map((x, index) => ({
      key: index,
      id: x.id,
      title: x.displayName,
      value: x.displayValue,
    }));
  };

  const getSelectedAnalysisOutput = () => {
    if (cardDateStore.selectedCardDates.length == 0) {
      return null;
    }

    const lastSelectedCardDate = cardDateStore.selectedCardDates.slice(-1)[0];
    return analysisStore.values[lastSelectedCardDate]?.output.map((x, index) => ({
      key: index,
      id: x.id,
      title: x.displayName,
      value: x.displayValue,
    }));
  };

  const getSelectedXDiagAnalysis = () => {
    if (cardDateStore.selectedCardDates.length == 0) {
      return '';
    }

    const lastSelectedCardDate = cardDateStore.selectedCardDates.slice(-1)[0];
    return analysisStore.values[lastSelectedCardDate]?.xdiagAnalysis.displayValue;
  };

  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  const handleClick = () => {
    setIsFullScreen(!isFullScreen);
  };

  useEffect(() => {
    const htmlElement = document.documentElement;
    if (isFullScreen) {
      htmlElement.classList.add('fullscreen');
    } else {
      htmlElement.classList.remove('fullscreen');
    }
  }, [isFullScreen]);

  return (
    <div className={isFullScreen ? 'full-screen' : ''}>
      <div className={`card-viewer ${isFullScreen ? 'card-viewer-full-screen-override' : ''}`}>
        <CardHeader value='Card viewer' icon='fullScreen' isFullScreen={isFullScreen} onClick={handleClick} />
        <div className='card-header-divider' />
        <div className='card-viewer-body'>
          <div className='card-viewer-icons'>
            <UIIconButtonToolbar uiIconButtons={uiIconButtonState} buttonClicked={toolbarButtonClicked} />
            <div className='card-viewer-button-container'>
              {activeViewToggle === 'view-toggle-2' && (
                <div className='card-viewer-date-picker-container'>
                  {showDateLinkIcon && (
                    <div onClick={() => onClickLinkIcon(0)}>
                      <TimelineIcon isDateLinked={isDateLinked} />
                    </div>
                  )}
                  <div className='card-calendar-width card-calendar'>
                    <TimelineDatepicker {...timelineProps} />
                  </div>
                </div>
              )}
              <Tooltip content='Card view options' direction='top'>
                <ViewOptions
                  label='View options'
                  options={cardViewOptions}
                  buttonContent={
                    <div data-testid='card-viewer-eye' className='card-viewer-button'>
                      <Eye />
                    </div>
                  }
                  onOptionChange={(id, value, name) => {
                    setSelectedCardTrends((cards) => {
                      const updatedCards = cards ? [...cards] : [];
                      if (value) {
                        if (id === 5) {
                          updatedCards.push({ id: 6, label: 'Permissible Load' });
                        }
                        updatedCards.push({ id: id, label: name });
                      } else {
                        if (id === 5) {
                          const index = updatedCards.findIndex((card) => card.id === 6);
                          if (index !== -1) {
                            updatedCards.splice(index, 1);
                          }
                        }
                        const index = updatedCards.findIndex((card) => card.id === id);
                        if (index !== -1) {
                          updatedCards.splice(index, 1);
                        }
                      }

                      return updatedCards;
                    });
                  }}
                  enabledStates={enabledStates}
                />
              </Tooltip>
              <Tooltip content='Card type filter' direction='top'>
                <CardTypeFilter
                  label='Filter card type'
                  options={distinctCardTypeNames}
                  buttonContent={
                    <div data-testid='card-viewer-filter' className='card-viewer-button'>
                      <FilterFunnel />
                    </div>
                  }
                  onOptionChange={(id, value, label) => {
                    handlecardTypeChange(id, value, label);
                  }}
                />
              </Tooltip>
            </div>
          </div>
          <div className='card-viewer-card'>
            <div className='flex flex-row flex-grow flex-shrink'>
              {isLoadingCards || isLoadingCoordinates ? (
                <Loader />
              ) : (
                <LidarScanMap selectedCardTrends={selectedCardTrends} cards={getSelectedCards()} />
              )}
              <div className='card-viewer-date-list'>
                <CardDateList
                  cardDateItems={dateRangeFilteredCardDates ?? cardDateStore.values}
                  onClick={onCardDateItemClickHandler}
                  cardTypeNames={selectedCardTypeNames}
                />
              </div>
            </div>
          </div>
          {activeViewToggle === 'view-toggle-2' && (
            <Timeline
              cards={cardDateStore.values}
              minDate={minDate}
              maxDate={maxDate}
              selectedStartDate={startDate}
              selectedEndDate={endDate}
              onChange={(startDate, endDate) => {
                setMinDate(startDate);
                setStartDate(startDate);
                setMaxDate(endDate);
                setEndDate(endDate);
              }}
            />
          )}
          <div className='rodliftanalysis-container ui'>
            <div className={`rodliftanalysis-header ${isCardDataCollapsed ? 'rodliftanalysis-header-collapsed' : ''}`}>
              {
                <AnalysisResultHeader
                  title='Card data'
                  description={createAnalysisResultHeaderDescription()}
                  isCollapsed={isCardDataCollapsed}
                  toggleCollapse={toggleBodyCollapse}
                  loading={!(isLoadingCards || isLoadingCoordinates)}
                />
              }
            </div>
            {!isCardDataCollapsed && (
              <div
                className={`${
                  activeViewToggle === 'view-toggle-2'
                    ? 'rodliftanalysis-body rodliftanalysis-body-with-timeline'
                    : 'rodliftanalysis-body'
                }`}
              >
                <div className='rodliftanalysis-scroll-area'>
                  {isLoadingCards || isLoadingCoordinates ? (
                    <Loader />
                  ) : (
                    <>
                      <AnalysisInputOutput
                        headerText='Input'
                        values={getSelectedAnalysisInput()}
                        loading={!isLoading}
                      />
                      <AnalysisInputOutput
                        headerText='Output'
                        values={getSelectedAnalysisOutput()}
                        loading={!isLoading}
                      />
                      <XDAnalysis headerText='Analysis data' value={getSelectedXDiagAnalysis()} loading={!isLoading} />
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RodLiftAnalysis;
