import React, { useEffect } from 'react';
import * as am5core from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { useAppSelector } from '../../hooks/storeHooks';
import Loader from '../common/Loader/Loader';

interface RGBColor {
  r: number;
  g: number;
  b: number;
}

interface CardCoordinate {
  x: number;
  y: number;
}

interface CardTrend {
  id: number;
  name: string;
  coordinates: CardCoordinate[];
}

interface Card {
  cardDate: string;
  cardTrends: CardTrend[];
  color: RGBColor | undefined;
}

interface SelectedCardTrend {
  id: number;
  label: string;
}

interface LidarScanProps {
  selectedCardTrends: SelectedCardTrend[];
  cards: Card[];
}

const LidarScanMap: React.FC<LidarScanProps> = (props: LidarScanProps) => {
  const isLoading = useAppSelector((state) => state.rodLiftAnalysisCardCoordinates.isLoading);
  const themeMode = useAppSelector((state) => state.theme.mode);
  const isDarkMode = themeMode === 'dark';

  let minX = 99999;
  let maxX = 0;

  let min = 0;
  let max = 100;
  const maxLoad = 0;
  const minLoad = 99999;
  let surfaceCardMax = 0;
  let surfaceCardMin = 0;

  useEffect(() => {
    const root = am5core.Root.new('lidarScanMapDiv');
    const chart: any = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: true,
        panX: true,
        wheelY: 'zoomX',
        layout: root.horizontalLayout,
        maxTooltipDistance: 0,
        paddingRight: 0,
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
        marginBottom: 0,
        marginRight: 0,
        marginLeft: 0,
      }),
    );
    const yAxisRender = am5xy.AxisRendererY.new(root, {});
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        extraTooltipPrecision: 1,
        renderer: yAxisRender,
        marginRight: 20,
      }),
    );
    yAxisRender.labels.template.set('fill', isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'));

    const rendererX = am5xy.AxisRendererX.new(root, { minGridDistance: 50 });
    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        extraTooltipPrecision: 1,
        renderer: rendererX,
        tooltip: am5core.Tooltip.new(root, {}),
        min: minX,
        max: maxX,
        strictMinMax: true,
      }),
    );
    rendererX.labels.template.set('fill', isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'));

    const createSeries = (
      data: any,
      isOpposite: boolean,
      customTooltip: any,
      seriesName: string,
      yAxisLabel: string,
      xAxisLabel: string,
    ) => {
      // Create a title element for yAxisWindDirection
      const yAxisLabelValue = am5core.Label.new(root, {
        text: yAxisLabel,
        fontWeight: '400',
        fontSize: '12px',
        rotation: -90,
        y: am5core.p50,
        centerX: am5core.p0,
        textAlign: 'center',
        fill: isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'),
      });

      const xAxisLabelValue = am5core.Label.new(root, {
        text: xAxisLabel,
        fontWeight: '400',
        fontSize: '12px',
        rotation: 0,
        x: am5core.p50,
        centerX: am5core.p0,
        textAlign: 'center',
        fill: isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'),
      });

      if (isOpposite) {
        return generateOppositeAxisSeries();
      } else {
        // sync each axis with the first one
        return generateDataAxisSeries();
      }

      function generateDataAxisSeries() {
        if (chart.yAxes.indexOf(yAxis) > 0) {
          yAxis.set('syncWithAxis', chart.yAxes.getIndex(0));
        }
        const rendererYAxis = yAxis.get('renderer');
        rendererYAxis.grid.template.removeAll();

        const labelValues: any = yAxis.children['_values'].filter((item: any) => item._settings.text === yAxisLabel);
        if (!labelValues || labelValues.length == 0) {
          yAxis.children.unshift(yAxisLabelValue);
        }

        const rendererX = xAxis.get('renderer');
        rendererX.grid.template.removeAll();
        const series = am5xy.LineSeries.new(root, {
          name: seriesName,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'y',
          valueXField: 'x',
          tooltip: am5core.Tooltip.new(root, {}),
        });
        const xLabelValue: any = xAxis.children['_values'].filter((item: any) => item._settings.text === xAxisLabel);
        if (!xLabelValue || xLabelValue.length == 0) {
          xAxis.children.push(xAxisLabelValue);
        }
        series.strokes.template.set('strokeWidth', 2);

        if (
          seriesName.includes('Permissible Load') ||
          seriesName.includes('Downhole Card') ||
          seriesName.includes('Predicted Card')
        ) {
          series.strokes.template.set('strokeDasharray', [3, 3]);
        }

        series.set('tooltip', customTooltip);
        series.data.setAll(data);
        xAxis.set(
          'tooltip',
          am5core.Tooltip.new(root, {
            themeTags: ['axis'],
          }),
        );

        yAxis.set(
          'tooltip',
          am5core.Tooltip.new(root, {
            themeTags: ['axis'],
          }),
        );
        return series;
      }

      function generateOppositeAxisSeries() {
        const rightYAxis = chart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {
              opposite: true,
              strokeOpacity: 0,
              strokeWidth: 0,
            }),
            min: min,
            max: max,
            step: 10,
            strictMinMax: true,
            marginLeft: 20,
          }),
        );

        rightYAxis.children.push(yAxisLabelValue);
        const rendererY = rightYAxis.get('renderer');
        rendererY.grid.template.removeAll();
        rendererY.labels.template.set('fill', isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'));

        // Add an additional category axis for the top of the chart
        const topAxisRenderer = am5xy.AxisRendererX.new(root, {
          opposite: true,
        });
        const topXAxis = chart.xAxes.push(
          am5xy.ValueAxis.new(root, {
            renderer: topAxisRenderer,
            min: 0,
            max: 100,
            strictMinMax: true,
            // label: {
            //   fill: am5core.color('#FF0000'), // Set font color here
            // },
          }),
        );
        topAxisRenderer.labels.template.set('fill', isDarkMode ? am5core.color('#F7F9F9') : am5core.color('#000000'));
        topXAxis.children.unshift(xAxisLabelValue);
        const rendererTopX = topXAxis.get('renderer');
        rendererTopX.grid.template.removeAll();

        const topXSeries = am5xy.LineSeries.new(root, {
          name: 'OppositeAxisSeries',
          xAxis: topXAxis,
          yAxis: rightYAxis,
          valueYField: 'y',
          valueXField: 'x',
        });

        topXSeries.strokes.template.set('strokeWidth', 1); // Hide the line
        topXSeries.hideTooltip();
        return topXSeries;
      }
    };

    function createLoadLimitSeries(yValue, seriesName) {
      const series = am5xy.LineSeries.new(root, {
        name: seriesName,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'y',
        valueXField: 'x',
      });

      // Create data for a horizontal line at y = yValue
      series.data.setAll([
        { x: minX, y: yValue },
        { x: maxX, y: yValue },
      ]);

      series.strokes.template.set('strokeWidth', 2);
      return series;
    }

    function createSingleMalfunctionPointSeries(xValue, yValue, seriesName, cardColor) {
      // Create a new XY series for the malfunction point
      const malfunctionSeries = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: seriesName,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'y',
          valueXField: 'x',
        }),
      );

      // Create a bullet for the malfunction point
      malfunctionSeries.bullets.push(function () {
        return am5core.Bullet.new(root, {
          sprite: am5core.Label.new(root, {
            text: '*',
            fill: cardColor,
            fontSize: 25,
            centerX: am5core.percent(50),
            centerY: am5core.percent(50),
          }),
          locationX: xValue,
          locationY: yValue,
        });
      });

      malfunctionSeries.data.push({ x: xValue, y: yValue });
      return malfunctionSeries;
    }

    function createFillageSetpointSeries(xValue, yValue, seriesName, cardColor) {
      const fillageSetpointLineSeries = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: seriesName,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'y',
          valueXField: 'x',
        }),
      );

      fillageSetpointLineSeries.data.setAll([
        { x: minX, y: yValue },
        { x: maxX, y: yValue },
        { x: xValue, y: yValue },
      ]);

      fillageSetpointLineSeries.strokes.template.set('strokeWidth', 2);
      fillageSetpointLineSeries.strokes.template.set('strokeDasharray', [3, 3]);
      fillageSetpointLineSeries.set('stroke', cardColor);

      // a separate chart must be used for the point series.
      const fillageSetpointSeries = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: seriesName + 'Point',
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'y',
          valueXField: 'x',
        }),
      );

      fillageSetpointSeries.bullets.push(() => {
        return am5core.Bullet.new(root, {
          sprite: am5core.Polygon.new(root, {
            fill: cardColor,
            points: [
              { x: xValue + 0, y: -8 },
              { x: xValue + 3, y: 0 },
              { x: xValue + 0, y: 8 },
              { x: xValue - 3, y: 0 },
            ],
            centerX: am5core.percent(50),
            centerY: am5core.percent(50),
          }),
        });
      });

      fillageSetpointSeries.data.push({ x: xValue, y: yValue });

      return fillageSetpointSeries;
    }

    const yAxisRangeDataItem = yAxis.makeDataItem({
      value: 10000,
      endValue: -10000,
    });
    const xAxisRangeDataItem = xAxis.makeDataItem({
      value: 200,
      endValue: -200,
    });

    // find min and max for x axis and y axis.
    props.selectedCardTrends.forEach((selectedCardTrend) => {
      props.cards.forEach((card) => {
        let min = 99999;
        let max = 0;
        let localMinX = 99999;
        let localMaxX = 0;
        card.cardTrends.forEach((cardTrend) => {
          if (
            cardTrend.coordinates.length == 0 ||
            cardTrend.id != selectedCardTrend.id ||
            (selectedCardTrend.id === 6 && cardTrend.id !== 7 && cardTrend.id !== 8) ||
            (selectedCardTrend.id === 9 && cardTrend.id !== 9 && cardTrend.id !== 13)
          ) {
            return;
          }

          const cardMinY = Math.min(...cardTrend.coordinates.map((coord) => coord.y));
          const cardMaxY = Math.max(...cardTrend.coordinates.map((coord) => coord.y));

          if (cardMinY < min) {
            min = cardMinY;
          }

          if (cardMaxY > max) {
            max = cardMaxY;
          }

          const cardMinX = Math.min(...cardTrend.coordinates.map((coord) => coord.x));
          const cardMaxX = Math.max(...cardTrend.coordinates.map((coord) => coord.x));

          if (cardMinX == Infinity || cardMinX == -Infinity || cardMaxX == Infinity || cardMaxX == -Infinity) {
            return;
          }

          if (cardMinX < localMinX) {
            localMinX = cardMinX;
          }

          if (cardMaxX > localMaxX) {
            localMaxX = cardMaxX;
          }
        });

        if (localMinX < minX) {
          xAxis.set('min', localMinX);

          minX = localMinX;
        }
        if (localMaxX > maxX) {
          xAxis.set('max', localMaxX);

          maxX = localMaxX;
        }
      });
    });

    props.selectedCardTrends.forEach((selectedCardTrend) => {
      props.cards.forEach((card) => {
        if (card) {
          const uniqueSeriesName = selectedCardTrend.label + card.cardDate;
          const customTooltip1: any = generateCustomToolkit(uniqueSeriesName);

          let cardTrend: CardTrend | undefined = undefined;

          cardTrend = card.cardTrends.filter((cardata) => cardata.id == selectedCardTrend.id).pop();

          if (!cardTrend) {
            return;
          }

          // handle special cases
          if (selectedCardTrend.id === 7) {
            if (props.selectedCardTrends.find((cardTrend) => cardTrend.id === 7)) {
              card.cardTrends
                .filter((cardTrend) => cardTrend.id === 7 || cardTrend.id === 8)
                .map((cardTrend) => {
                  const loadLimitSeries = createLoadLimitSeries(
                    cardTrend.coordinates[0].y,
                    `${cardTrend.name} - ${uniqueSeriesName}`,
                  );
                  if (card.color) {
                    loadLimitSeries.set(
                      'stroke',
                      am5core.color(`rgb(${card.color.r}, ${card.color.g}, ${card.color.b})`),
                    );
                  }
                  chart.series.push(loadLimitSeries);
                });

              return;
            }
          }

          if (selectedCardTrend.id === 9) {
            if (props.selectedCardTrends.find((cardTrend) => cardTrend.id === 1)) {
              const cardTrend = card.cardTrends.find((cardTrend) => cardTrend.id === 9);
              if (cardTrend && cardTrend.coordinates.length > 0) {
                createSingleMalfunctionPointSeries(
                  cardTrend.coordinates[0].x,
                  cardTrend.coordinates[0].y,
                  `${cardTrend.name} - ${uniqueSeriesName}`,
                  card.color
                    ? am5core.color(`rgb(${card.color.r}, ${card.color.g}, ${card.color.b})`)
                    : am5core.color('rgb(0, 0, 0)'),
                );
              }
            }

            if (props.selectedCardTrends.find((cardTrend) => cardTrend.id === 2)) {
              const cardTrend = card.cardTrends.find((cardTrend) => cardTrend.id === 13);
              if (cardTrend && cardTrend.coordinates.length > 0) {
                createFillageSetpointSeries(
                  cardTrend.coordinates[0].x,
                  cardTrend.coordinates[0].y,
                  `${cardTrend.name} - ${uniqueSeriesName}`,
                  card.color
                    ? am5core.color(`rgb(${card.color.r}, ${card.color.g}, ${card.color.b})`)
                    : am5core.color('rgb(0, 0, 0)'),
                );
              }
            }

            return;
          }

          if (cardTrend?.id === 1) {
            surfaceCardMin = Math.min(...cardTrend.coordinates.map((coord) => coord.y));
            surfaceCardMax = Math.max(...cardTrend.coordinates.map((coord) => coord.y));

            calculateCustomLoadAxis(surfaceCardMin, surfaceCardMax, max, min);
          }

          if (cardTrend) {
            const series = createSeriesWithRange(
              cardTrend?.coordinates,
              false,
              customTooltip1,
              uniqueSeriesName,
              'Load (lbs)',
              'Pos (inches)',
              yAxisRangeDataItem,
              xAxisRangeDataItem,
            );

            // Set color.
            if (card.color) {
              series.set('stroke', am5core.color(`rgb(${card.color.r}, ${card.color.g}, ${card.color.b})`));
            }

            chart.series.push(series);
          }
        }
      });
    });
    calculateCustomLoadAxis(maxLoad, minLoad);
    function calculateCustomLoadAxis(charMax, chartMin) {
      const surfaceCardHeight = surfaceCardMax - surfaceCardMin;
      const aboveSurfaceAmount = charMax + 500 - surfaceCardMax;
      const belowSurfaceAmount = surfaceCardMin - (chartMin - 500);

      if (surfaceCardHeight > 0) {
        const aboveSurfaceRatio = aboveSurfaceAmount / surfaceCardHeight;
        const belowSurfaceRatio = belowSurfaceAmount / surfaceCardHeight;
        max = 100 + aboveSurfaceRatio * 100;
        min = 0 - belowSurfaceRatio * 100;
      }
    }

    const customTooltip: any = generateCustomToolkit('performanceCurveSeries');

    const oppositeSeries = createSeriesWithRange(
      [],
      true,
      customTooltip,
      'OppositeAxisSeries',
      'Load (%)',
      'Pos (%)',
      null,
      null,
    );

    chart.series.push(oppositeSeries);

    root.setThemes([am5themes_Animated.new(root)]);
    if (themeMode === 'dark') {
      chart.plotContainer.get('background').setAll({
        fillGradient: am5core.LinearGradient.new(root, {
          rotation: 90,
          stops: [
            { color: am5core.color('#253240'), opacity: 1, offset: 0 },
            { color: am5core.color('#253040'), opacity: 0.4, offset: 1 },
          ],
        }),
        fillOpacity: 1,
      });
    } else {
      chart.plotContainer.get('background').setAll({
        fill: am5core.color('#F7F9F9'),
        fillOpacity: 1,
      });
    }
    const customCursor: any = am5xy.XYCursor.new(root, {});
    chart.set('cursor', customCursor);

    return () => {
      root.dispose();
    };

    function generateCustomToolkit(seriesName: string) {
      const customTooltip: any = am5core.Tooltip.new(root, {
        getFillFromSprite: false,
        pointerOrientationi: 'left',
        autoTextColor: false,
        showTooltipOn: 'always',
      });

      customTooltip.label.set('fontSize', 12);
      customTooltip.get('background').setAll({
        fill: am5core.color('#001023'),
        opacity: 0.6,
        strokeWidth: 1,
        stroke: am5core.color('#4A5463'),
      });

      customTooltip.label.setAll({
        fill: am5core.color('#000000'),
      });

      //Tooltip for emission(kg/hr)
      customTooltip.label.adapters.add('html', function (text: string) {
        text = '';
        let currentDataContext = undefined;
        chart.series.each(function (series: any) {
          const currentSeriesName = series.get('name');

          if (currentSeriesName && currentSeriesName == 'OppositeAxisSeries' && currentDataContext) {
            const xValue = currentDataContext?.x;
            const yValue = currentDataContext?.y;

            const loadPercent = Math.round(((yValue - surfaceCardMin) / (surfaceCardMax - surfaceCardMin)) * 100);
            const posPercent = Math.round((xValue / maxX) * 100);
            text += '<div>Load(%)' + '<span style="margin-left:15px">' + loadPercent + ' </span></div>';
            text += '<div>Pos(%)' + '<span style="margin-left:25px">' + posPercent + ' </span></div>';
          }
          if (currentSeriesName && currentSeriesName == seriesName) {
            text += '<div >Load(lbs)' + '<span style="margin-left:10px">' + '{valueY}' + ' </span></div>';
            text += '<div>Pos(in.)' + '<span style="margin-left:20px">' + '{valueX}' + ' </span></div>';
            currentDataContext = series.get('tooltipDataItem')?.dataContext;
          }
        });
        return text;
      });
      return customTooltip;
    }
    function createSeriesWithRange(
      data: CardCoordinate[],
      opposite: boolean,
      tooltip: any,
      seriesName: string,
      yAxisLabel: string,
      xAxisLabel: string,
      yAxisRangeDataItem: any,
      xAxisRangeDataItem: any,
    ) {
      const series = createSeries(data, opposite, tooltip, seriesName, yAxisLabel, xAxisLabel);
      if (yAxisRangeDataItem) series.createAxisRange(yAxisRangeDataItem);
      if (xAxisRangeDataItem) series.createAxisRange(xAxisRangeDataItem);
      return series;
    }
  }, [props.cards.length, props.selectedCardTrends, themeMode]);

  return (
    <div id='lidarScanMapDiv' style={{ width: '95%' }}>
      {isLoading && <Loader />}
    </div>
  );
};

export default LidarScanMap;
